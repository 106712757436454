import React, { useState, useRef, useEffect } from "react"

import "./rotatingbar.css"

const BALANCING_DEGREE = 180

const RotatingBar = ({ angle, setAngle }) => {
  const [state, setState] = useState({
    mouseMove: false,
  })
  const elementRef = useRef(null)
  useEffect(() => {
    window.addEventListener("mouseup", handleMouseUp)
    return () => {
      window.removeEventListener("mouseup", handleMouseUp)
    }
  })
  const handleMouseDown = e => {
    e.stopPropagation()
    setState({ mouseMove: true })
  }
  const handleMouseUp = () => {
    setState({ mouseMove: false })
  }
  const handleMouseMove = e => {
    // e.stopPropagation()
    const element = elementRef?.current
    if (element) {
      const boxCenter = [
        element.offsetParent.offsetLeft +
          element.offsetLeft +
          element.offsetWidth / 2,
        element.offsetParent.offsetTop +
          element.offsetTop +
          element.offsetHeight / 2,
      ]
      const rawAngle =
        Math.atan2(e.pageX - boxCenter[0], -(e.pageY - boxCenter[1])) *
        (180 / Math.PI)
      if (state.mouseMove) {
        const angleNow = rawAngle + BALANCING_DEGREE
        if (e.shiftKey) {
          if (Math.floor(angleNow % 9) <= 4 || Math.floor(9 % angleNow) <= 4) {
            const diff = Math.round(angleNow % 15)
            setAngle(Math.round(angleNow - diff))
          }
        } else if (e.ctrlKey) {
          if (Math.floor(angleNow % 9) <= 3 || Math.floor(9 % angleNow) <= 3) {
            const diff = Math.round(angleNow % 9)
            setAngle(Math.round(angleNow - diff))
          }
        } else {
          if (
            Math.floor(angleNow % 45) <= 5 ||
            Math.floor(45 % angleNow) <= 5
          ) {
            const diff = Math.round(angleNow % 45)
            setAngle(Math.round(angleNow - diff))
          }
        }
      }
    }
  }
  return (
    <div
      role="button"
      tabIndex={0}
      onKeyPress={() => false}
      ref={elementRef}
      className="drag-area"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        height: "120px",
        width: "120px",
        backgroundColor: "rgba(0, 0, 0, 0)",
      }}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
    >
      <div
        className="drag-bar"
        style={{
          position: "relative",
          height: 35,
          width: 2,
          top: 17.5,
          cursor: "pointer",
          backgroundColor: "#fff",
          border: "2px solid #0D004D",
          transformOrigin: "45% 0%",
          transform: `rotate(${angle}deg) translateY(15px)`,
          borderRadius: 100,
          boxSizing: "content-box",
        }}
      />
    </div>
  )
}

export { RotatingBar }
