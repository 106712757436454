import React, { useState, useEffect } from "react"
import styled from "styled-components"

import "./minbutton.css"
import { LoadingCircle } from "./loadingcircle"
import { SuccessAnimation } from "./successanimation"

const MinButtonMain = styled.span`
  ${props => props.btnStyle}
`

const MinButton = ({
  title,
  afterClickTitle,
  btn,
  onClick,
  leftIcon,
  leftIconSuccess,
  rightIcon,
  rightIconSuccess,
  btnStyle,
  onHoverText,
}) => {
  const [btnText, setBtnText] = useState("")
  let timeOut
  useEffect(() => {
    setBtnText(title)
    return () => {
      clearTimeout(timeOut)
    }
  }, [title, timeOut])
  const handleMouseEnter = () => {
    if (onHoverText) {
      setBtnText(onHoverText)
    }
  }
  const handleMouseLeave = () => {
    if (onHoverText) {
      setBtnText(title)
    }
  }
  const clickHandler = () => {
    if (onClick) onClick()
    if (afterClickTitle) {
      setBtnText(afterClickTitle)
      timeOut = setTimeout(() => {
        setBtnText(title)
      }, 1000)
    }
  }
  return (
    <MinButtonMain
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={`min-button-main ${btn ? btn : ""}`}
      onClick={clickHandler}
      btnStyle={btnStyle}
    >
      {leftIcon &&
        (leftIcon === "animation" ? (
          <LoadingCircle />
        ) : leftIconSuccess === "successanimation" ? (
          <SuccessAnimation />
        ) : (
          <span
            className={`left icon ${
              leftIconSuccess ? leftIconSuccess : leftIcon
            }`}
          ></span>
        ))}{" "}
      {btnText}{" "}
      {rightIcon && (
        <span
          className={`right icon ${
            rightIconSuccess ? rightIconSuccess : rightIcon
          }`}
        ></span>
      )}
    </MinButtonMain>
  )
}

MinButton.defaultProps = {
  title: `Add title`,
}

export { MinButton }
