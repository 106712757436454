import { shuffleArray } from "./shuffleArray"

const ITEM_GROUP_THRESHOLD = 3
const MAX_ITEMS = 9

const allPossibleTagsArray = tags => {
  const tagsArray = []
  for (let i = tags.length - 1; i >= 0; i--) {
    tagsArray.push(String(tags[i]).toLowerCase())
  }
  for (let i = tags.length - 1; i > 0; i--) {
    tagsArray.push(String(tags.slice(0, i + 1)).toLowerCase())
  }
  return tagsArray.sort()
}

const tagsSeperation = tags => {
  const tagsObj = {}
  const sortedTags = tags.sort()
  for (let i = tags.length - 1; i >= 0; i--) {
    const tagString = String(sortedTags[i]).toLowerCase()
    tagsObj[tagString] = []
  }
  for (let i = tags.length - 1; i > 0; i--) {
    const tagString = String(sortedTags.slice(0, i + 1)).toLowerCase()
    tagsObj[tagString] = []
  }
  return tagsObj
}

export const relatedItems = (currentItem, items) => {
  let result = []
  const processedItems = []
  const currentItemId = currentItem.id
  const currentItemTags = currentItem.tags
  const currentItemTagsObj = tagsSeperation(currentItemTags)

  items.forEach(item => {
    const itemTags = item.node.frontmatter.tags
    const itemTagsArray = allPossibleTagsArray(itemTags)
    itemTagsArray.forEach(itemTag => {
      Object.keys(currentItemTagsObj).forEach(currentItemTag => {
        if (
          item.node.frontmatter.id !== currentItemId &&
          itemTag === currentItemTag
        ) {
          processedItems.push(item)
        }
      })
    })
  })

  const randomItems = shuffleArray(processedItems)
  const limitedItems = randomItems.slice(0, MAX_ITEMS)
  const limitedItemsSize = limitedItems.length
  limitedItems.splice(
    limitedItemsSize - (limitedItemsSize % ITEM_GROUP_THRESHOLD)
  )
  result = limitedItems
  return result
}
