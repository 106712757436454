import React, { useState } from "react"
import { CSSTransition, TransitionGroup } from "react-transition-group"

import Sorter from "../home/sorter"

import { LoadingButton } from "./loadingbutton"
import { GradientCard } from "./gradientcard"

import { itemsByTag, shuffleArray } from "../../utils"

import "./gradients.css"

let TOTAL_ACTIVE_ITEMS = 15

if (typeof window !== "undefined") {
  if (window.innerWidth <= 360) {
    TOTAL_ACTIVE_ITEMS = 5
  } else if (window.innerWidth <= 768) {
    TOTAL_ACTIVE_ITEMS = 10
  } else {
    TOTAL_ACTIVE_ITEMS = 15
  }
}

const SORT_BY = [
  "Fresh New",
  "Popular This Week",
  "Popular This Month",
  "All Time Popular",
]

const Gradients = ({
  related,
  items,
  allTimePopularItems,
  lastWeekPopularItems,
  lastMonthPopularItems,
}) => {
  const [active, setActive] = useState({
    items: TOTAL_ACTIVE_ITEMS,
    step: TOTAL_ACTIVE_ITEMS,
    tag: "all",
    type: SORT_BY[0],
    color: null,
  })
  let itemsByType
  if (active.type === SORT_BY[0]) {
    itemsByType = items
  } else if (active.type === SORT_BY[1]) {
    itemsByType = lastWeekPopularItems
  } else if (active.type === SORT_BY[2]) {
    itemsByType = lastMonthPopularItems
  } else if (active.type === SORT_BY[3]) {
    itemsByType = allTimePopularItems
  } else {
    const copiedItems = [...items]
    itemsByType =
      typeof window !== `undefined`
        ? shuffleArray(copiedItems)
        : shuffleArray(copiedItems)
  }
  const indItems = itemsByTag(itemsByType)
  const tags = Object.keys(indItems)
  const activeTagItems = indItems[active.tag]
  const activeTagItemsTotal = activeTagItems.length
  const activeItems = activeTagItems.splice(0, active.items)

  const handleClick = type => {
    setActive({
      ...active,
      items: TOTAL_ACTIVE_ITEMS,
      type,
    })
  }

  const handleLoadMore = () => {
    setActive({
      ...active,
      items: active.items + active.step,
    })
  }

  const setActiveType = type => {
    setActive({
      ...active,
      type,
    })
  }

  const setColor = color => {
    setActive({
      ...active,
      color,
    })
  }

  return (
    <div className="gradients-main">
      {related ? (
        <div className="related-head">
          <h1>Related Gradients</h1>
        </div>
      ) : (
        <Sorter
          activeTag={active.tag}
          types={SORT_BY}
          activeType={active.type}
          setActiveType={setActiveType}
          tags={tags}
          onClick={handleClick}
          color={active.color}
          setColor={setColor}
        />
      )}
      <div>
        <TransitionGroup className="gradients-list">
          {activeItems.map(item => (
            <CSSTransition
              key={item.node.frontmatter.id}
              timeout={200}
              classNames="item"
            >
              <GradientCard key={item.node.frontmatter.id} {...item} />
            </CSSTransition>
          ))}
        </TransitionGroup>
      </div>
      {activeTagItemsTotal > 15 && (
        <div className="gradients-action">
          {active.items < activeTagItemsTotal ? (
            <LoadingButton onClick={handleLoadMore} />
          ) : (
            <div className="message">
              <p>Sorry, you are end of the result!</p>
              <p>
                And, if you want to use the <strong>Load More</strong> button
                again, refresh the page! :P
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export { Gradients }
