import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import { QuickButton } from "./quickbutton"

import "./gradientcard.css"

const Card = styled.div`
  ${props => props.css}
`

const GradientCard = React.memo(({ node }) => {
  const { id, css } = node.frontmatter
  return (
    <Card css={css} className="card">
      <Link to={`/${id}`}>
        <div className="card-content" />
      </Link>
      <div className="card-action">
        <ul>
          <li className="bottom left">
            <QuickButton
              CSS={css}
              title="CSS"
              onHoverText="Copy CSS"
              leftIcon="icon-copy larger-icon"
              leftIconSuccess="icon-check larger-icon"
            />
          </li>
          <li className="bottom right">
            <Link to={`/${id}`}>
              <QuickButton
                title="View"
                onHoverText="Live View"
                rightIcon="icon-view larger-icon"
              />
            </Link>
          </li>
        </ul>
      </div>
    </Card>
  )
})

export { GradientCard }
