import React from "react"
import styled from "styled-components"

import "./tooltip.css"

const ToolTipContainer = styled.span`
  position: relative;
`
const ToolTipInfo = styled.div`
  pointer-events: none;
  opacity: 0;
  position: absolute;
  top: ${props => (typeof props.bottom !== "undefined" ? "auto" : "-50px")};
  bottom: ${props => (typeof props.bottom !== "undefined" ? "-50px" : "auto")};
  background-color: #fff;
  text-align: center;
  padding: 5px 10px;
  box-shadow: 0px 4px 16px rgba(13, 0, 77, 0.08);
  border-radius: 4px;
  transform: translateY(-10px);
  transition: 0.2s all;
  z-index: 1;
  :before {
    content: "";
    position: absolute;
    left: calc(50% - 5px);
    height: 10px;
    width: 10px;
    background: #fff;
    bottom: ${props => (typeof props.bottom !== "undefined" ? "auto" : "-5px")};
    top: ${props => (typeof props.bottom !== "undefined" ? "-5px" : "auto")};
    transform: rotate(45deg);
  }
`

const ToolTip = ({ children, bottom, title }) => {
  const width = title.length * 10
  return (
    <ToolTipContainer className="tooltip">
      <ToolTipInfo
        bottom={bottom}
        style={{
          display: "inline-block",
          padding: "8px 16px",
          fontSize: 14,
          lineHeight: "20px",
          color: "#0D004D",
          right: `calc(50% - ${width / 2}px)`,
          width: `${width}px`,
        }}
        className="info"
      >
        {title}
      </ToolTipInfo>{" "}
      {children}
    </ToolTipContainer>
  )
}

export { ToolTip }
