import React, { useState } from "react"

import DownloadBox from "./downloadbox"

import { Button, MinButton } from "../common"

import "./downloadoption.css"

const DownloadOption = ({ id, isLight, CSS }) => {
  const [show, setShow] = useState(false)

  const handleShow = () => {
    setShow(!show)
  }

  return (
    <>
      <li
        onKeyPress={() => false}
        onClick={() => setShow(!show)}
        className={`download ${show ? "show" : ""} ${
          isLight ? "light-bg" : "dark-bg"
        }`}
      >
        <Button
          btn={`button-shadow button-position-relative ${
            isLight ? "button-dark-bg" : "button-white-bg"
          }`}
        >
          <MinButton leftIcon="icon-download" title="Downlod JPG" />
        </Button>
      </li>
      {show && <DownloadBox id={id} CSS={CSS} handleShow={handleShow} />}
    </>
  )
}

export default DownloadOption
