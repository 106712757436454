import React, { useState } from "react"
import styled from "styled-components"

import LiveView from "./liveview"

import { Container, Nav, LoadingDot } from "../common"

import { randomItemTitle } from "../../utils"

import "./itemheader.css"
import ItemAction from "./itemaction"

const Header = styled.header`
  ${props => (props.CSS && !props.svgActive ? props.CSS : "")};
  background-image: ${props => props.svgActive && `url(${props.svg})`};
`

const ItemHeader = ({ item }) => {
  const { id, enable_dark_hero_content } = item
  const [svgActive, setSvgActive] = useState(false)
  const [title, setTitle] = useState(randomItemTitle())
  const changeTitle = title => {
    setTitle("...")
    setTimeout(() => {
      setTitle(title)
    }, 1000)
  }
  const handleSwitch = status => {
    setTimeout(() => {
      setSvgActive(!status)
    }, 1000)
    changeTitle(
      status ? "You Are Viewing CSS Version!" : "You Are Viewing SVG Version!"
    )
  }

  const isLight = enable_dark_hero_content || false

  return (
    <>
      <Header className="header" svgActive={svgActive} CSS={item.css}>
        <Container>
          <Nav isLight={isLight} />
          <div className="item-intro">
            <div className="intro-title">
              <h1 className={isLight ? "light" : "normal"}>
                {title === "..." ? <LoadingDot type="big" /> : title}
              </h1>
              <ItemAction
                handleSwitch={handleSwitch}
                changeTitle={changeTitle}
                id={id}
                isLight={isLight}
                CSS={item.css}
              />
            </div>
            <LiveView isLight={isLight} {...item} />
          </div>
        </Container>
      </Header>
    </>
  )
}

export default ItemHeader
