import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import { Container } from "./container"

import { Brand } from "./brand"

import { numberWithCommas } from "../../utils"

import "./footer.css"

const FooterMain = styled.div`
  ${props => props.CSS}
`

const Footer = ({ noExtraFootPad, footerGradient, stats, totalItems }) => {
  let csscopied, svgjpgdownload
  if (stats) {
    csscopied = stats.stats.csscopied
    svgjpgdownload = stats.stats.svgjpgdownload
  }
  const metomicHandle = () => {
    if (typeof window !== "undefined") {
      window.Metomic("ConsentManager:show")
    }
  }
  return (
    <FooterMain
      className="footer"
      CSS={footerGradient && footerGradient.node.frontmatter.css}
    >
      <Container>
        <div
          className={`copyright ${
            noExtraFootPad ? "no-extra-pad" : "extra-pad"
          }`}
        >
          <div className="foot-logo">
            <Brand />
          </div>
          <p>
            An Exciting Side Project Made With Love By{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://twitter.com/HiShahadat"
            >
              @HiShahadat
            </a>
          </p>
          {stats && (
            <ul>
              <li>
                <p>{numberWithCommas(totalItems)} Gradient Codes</p>
              </li>
              <li>
                <p>{numberWithCommas(csscopied)} CSS Copied</p>
              </li>
              <li>
                <p>{numberWithCommas(svgjpgdownload)} JPG Downloads</p>
              </li>
            </ul>
          )}
        </div>
      </Container>
      {footerGradient && (
        <Link
          className="current-gradient"
          to={`/${footerGradient.node.frontmatter.id}`}
        >
          {footerGradient.node.frontmatter.title}
        </Link>
      )}
      <span
        role="button"
        tabIndex={0}
        onKeyPress={() => false}
        onClick={metomicHandle}
      />
    </FooterMain>
  )
}

export { Footer }
