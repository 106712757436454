export const itemsByTag = items => {
  const indItems = {
    all: [],
    dark: [],
    light: [],
    simple: [],
    vibrant: [],
    contrasty: [],
    abstract: [],
    noisy: [],
    other: [],
  }
  items.forEach(item => {
    const tagsArray = item.node.frontmatter.tags
    indItems.all.push(item)
    tagsArray.forEach(tag => {
      const trimmedTag = String(tag)
        .trim()
        .toLowerCase()
      if (indItems[trimmedTag]) {
        indItems[trimmedTag].push(item)
      } else {
        indItems[trimmedTag] = [item]
      }
    })
  })
  return indItems
}
