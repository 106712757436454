const EMAIL_VALIDITY_REGEX = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/

let allOkay = []

export const checkContactInput = (name, value) => {
  if (name === "name") {
    if (String(value).length > 2) {
      allOkay[0] = true
    } else {
      allOkay[0] = false
    }
  } else if (name === "email") {
    if (EMAIL_VALIDITY_REGEX.test(value)) {
      allOkay[1] = true
    } else {
      allOkay[1] = false
    }
  } else if (name === "message") {
    if (String(value).length > 10) {
      allOkay[2] = true
    } else {
      allOkay[2] = false
    }
  }
  return allOkay.length === 3 && allOkay.every(one => one)
}
