import React from "react"
import ReactGA from "react-ga"

import DownloadOption from "./downloadoption"

import { Button, MinButton } from "../common"

import "./itemaction.css"

const ItemAction = ({ id, isLight, svg, CSS, changeTitle }) => {
  const handleCopy = () => {
    if (typeof navigator !== "undefined") {
      navigator.clipboard.writeText(CSS).then(() => {
        changeTitle("Your CSS Code Has Been Copied!")
        ReactGA.pageview(`/$gr-css-copy/${id}`)
      })
    }
  }

  return (
    <div className="item-action-main">
      <ul>
        <DownloadOption
          id={id}
          svg={svg}
          isLight={isLight}
          CSS={CSS}
          changeTitle={changeTitle}
        />
        <li>
          <Button btn="button-transparent" onClick={handleCopy}>
            <MinButton
              btn={`min-button-text-shadow ${
                isLight ? "min-button-light-bg" : "min-button-dark-bg"
              }`}
              leftIcon="icon-copy"
              title="Copy CSS"
            />
          </Button>
        </li>
      </ul>
    </div>
  )
}

export default ItemAction
