import React from "react"

import "./loadingdot.css"

const LoadingDot = ({ type }) => {
  return (
    <div className={`${type} lds-ellipsis`}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}

LoadingDot.defaultProps = {
  type: "normal",
}

export { LoadingDot }
