import React, { useState } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Helmet } from "react-helmet"

import { Nav } from "./nav"
import { Container } from "./container"

import "./header.css"

const HeaderMain = styled.header`
  ${props => props.css}
`

const Header = ({ title, description, defaultGradient, noIntro }) => {
  return (
    <>
      <HeaderMain
        css={defaultGradient && defaultGradient.node.frontmatter.css}
        className={`header-main ${!noIntro ? "default-gradient" : "no-intro"}`}
      >
        <Container>
          <Nav isLight={noIntro} />
          {!noIntro && (
            <div className="intro">
              <div className="intro-title">
                <h1>{title}</h1>
                <p>{description}</p>
                {description && (
                  <div style={{ marginTop: 25 }}>
                    <a
                      rel="noopener noreferrer"
                      href="https://www.producthunt.com/posts/gradienta?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-gradienta"
                      target="_blank"
                    >
                      <img
                        src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=178288&theme=light&period=daily"
                        alt="Codioful - Open-source multi-layered CSS gradients with JPG downloads | Product Hunt Embed"
                        style={{ width: 250, height: 54 }}
                        width="250px"
                        height="54px"
                      />
                    </a>
                  </div>
                )}
              </div>
            </div>
          )}
        </Container>
        {defaultGradient && (
          <Link
            className="current-gradient"
            to={`/${defaultGradient.node.frontmatter.id}`}
          >
            {defaultGradient.node.frontmatter.title}
          </Link>
        )}
      </HeaderMain>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export { Header }
