import React, { useState, useEffect } from "react"

import { Button } from "./button"
import { LoadingDot } from "./loadingdot"
import { MinButton } from "./minbutton"

const LoadingButton = ({ onClick }) => {
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    let timeOut
    if (loading) {
      timeOut = setTimeout(() => {
        setLoading(false)
      }, 1000)
    }

    return () => {
      clearTimeout(timeOut)
    }
  })
  const handleClick = () => {
    setLoading(true)
    setTimeout(onClick, 0)
  }
  return (
    <Button btn="button-big" onClick={handleClick}>
      {loading ? <LoadingDot /> : <MinButton title="Load More" />}
    </Button>
  )
}

export { LoadingButton }
