import React, { useState, useEffect } from "react"

import { MinButton } from "./minbutton"

const QuickButton = ({
  CSS,
  title,
  onHoverText,
  leftIcon,
  leftIconSuccess,
  rightIcon,
}) => {
  const [theTitle, setTheTitle] = useState(title)
  useEffect(() => {
    let timrOutTitle
    if (theTitle !== title) {
      timrOutTitle = setTimeout(() => {
        setTheTitle(title)
      }, 1000)
    }
    return () => {
      clearTimeout(timrOutTitle)
    }
  })
  const handleClick = () => {
    if (CSS) {
      if (typeof navigator !== "undefined") {
        navigator.clipboard.writeText(CSS).then(() => {
          setTheTitle("Copied!")
        })
      }
    }
  }
  return (
    <>
      <MinButton
        onClick={handleClick}
        title={theTitle}
        onHoverText={onHoverText}
        leftIcon={leftIcon}
        rightIcon={rightIcon}
        leftIconSuccess={theTitle !== title && "successanimation"}
      />
    </>
  )
}

export { QuickButton }
