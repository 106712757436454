import React from "react"

import "./sheet.css"

const Sheet = ({ children, fullWidth, mt }) => {
  return (
    <div
      style={{ marginTop: mt && mt }}
      className={`sheet-main ${fullWidth && "full-width"}`}
    >
      {children}
    </div>
  )
}

export { Sheet }
