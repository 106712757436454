import React, { useState } from "react"

const UNITS = ["px", "em", "%"]

const VariablUnit = ({ name, type, handleUnitChange }) => {
  const handleClick = e => {
    const findIndex = UNITS.findIndex(one => one === type)
    const nextIndex = findIndex + 1 === UNITS.length ? 0 : findIndex + 1
    handleUnitChange(e, name, UNITS[nextIndex])
  }
  return (
    <span
      role="button"
      tabIndex={0}
      onKeyPress={() => false}
      onClick={handleClick}
    >
      {type}
    </span>
  )
}

VariablUnit.defaultProps = {
  type: UNITS[0],
}

export { VariablUnit }
