const titles = [
  "Awesome Gradient, Mate!",
  "You Picked A Nice Codioful!",
  "Hmm, Good Choice!",
  "You Like This Codioful, Huh?",
  "OMG, I Need This Codioful!",
  "Good! Grand!! Codioful!!!",
  "Gr, Grr, Grad, Gradient(a)?",
  "Noise or Grain? No, Codioful!",
  "Ting Tong! You’ve Got A Codioful!",
  "Graphic Designer? Get A Codioful!",
  "Gradi + Enta = Codioful! :/",
  "This Is A Nice Codioful, Yup!",
]
const length = titles.length
export const randomItemTitle = () => {
  const random = Math.round(Math.random() * (length - 1))
  return titles[random]
}
