import React from "react"

import "./successanimation.css"

const SuccessAnimation = () => {
  return (
    <div className="success-checkmark">
      <div className="check-icon">
        <span className="icon-line line-tip"></span>
        <span className="icon-line line-long"></span>
        <div className="icon-fix"></div>
      </div>
    </div>
  )
}

export { SuccessAnimation }
