import React from "react"

const Logo = ({ color }) => {
  return (
    <svg
      width="148"
      height="40"
      viewBox="0 0 148 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M39 13.7479C36.3752 5.76416 28.8598 0 19.9982 0C8.95356 0 0 8.95428 0 20C0 31.0457 8.95356 40 19.9982 40C28.8598 40 36.3752 34.2358 39 26.2521L28.9975 20L39 13.7479ZM19.9982 29C15.0282 29 10.999 24.9706 10.999 20C10.999 15.0294 15.0282 11 19.9982 11C24.9683 11 28.9975 15.0294 28.9975 20C28.9975 24.9706 24.9683 29 19.9982 29Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M86.1876 22C88.4044 22 89.9252 20.8991 90.3892 18.8807H90.9047V21.6592H94.7197V2H90.9047V10.7549H90.3892C89.9252 9.07732 88.4044 7.63564 86.1876 7.63564C82.8108 7.63564 80.5167 10.7811 80.5167 14.8178C80.5167 18.8545 82.8108 22 86.1876 22ZM84.409 14.8178C84.409 12.6422 85.904 11.1743 87.76 11.1743C89.5128 11.1743 91.0852 12.6422 91.0852 14.8178C91.0852 16.9934 89.5128 18.4613 87.76 18.4613C85.904 18.4613 84.409 16.9934 84.409 14.8178Z"
        fill={color}
      />
      <path
        d="M123.819 21.6592H120.004V11.2529H117.478V7.9764H121.002C121.002 7.9764 120.081 7.9764 120.081 5.96555C120.081 2.93614 122.324 2 125.03 2H126.937V5.35517H125.262C123.543 5.35517 122.898 5.85321 122.898 6.84928C122.898 7.47177 123.394 7.9764 124.006 7.9764H127.196V11.2529H123.819V21.6592Z"
        fill={color}
      />
      <path
        d="M137.142 18.8807C136.756 20.5321 135.364 22 133.456 22C130.543 22 129.255 19.7981 129.255 17.4128V7.9764H133.07V15.8663C133.07 17.2031 133.972 18.2254 135.338 18.2254C136.756 18.2254 137.658 17.2031 137.658 15.8663V7.9764H141.473V21.6592H137.658V18.8807H137.142Z"
        fill={color}
      />
      <path d="M144.185 21.6592H148V2H144.185V21.6592Z" fill={color} />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M117.027 14.8178C117.027 18.9594 113.756 22 109.911 22C105.816 22 102.795 18.9594 102.795 14.8178C102.795 10.7025 105.816 7.63566 109.911 7.63566C113.756 7.63566 117.027 10.7025 117.027 14.8178ZM109.911 11.1743C108.063 11.1743 106.54 12.6422 106.54 14.8178C106.54 17.0721 108.063 18.4613 109.911 18.4613C111.758 18.4613 113.282 17.0721 113.282 14.8178C113.282 12.6422 111.758 11.1743 109.911 11.1743Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M71.9 22C75.7453 22 79.0163 18.9594 79.0163 14.8178C79.0163 10.7025 75.7453 7.63566 71.9 7.63566C67.805 7.63566 64.7838 10.7025 64.7838 14.8178C64.7838 18.9594 67.805 22 71.9 22ZM68.5292 14.8178C68.5292 12.6422 70.0523 11.1743 71.9 11.1743C73.7477 11.1743 75.2709 12.6422 75.2709 14.8178C75.2709 17.0721 73.7477 18.4613 71.9 18.4613C70.0523 18.4613 68.5292 17.0721 68.5292 14.8178Z"
        fill={color}
      />
      <path
        d="M63.4822 15.945C62.3559 19.405 59.3198 22 55.0594 22C49.7462 22 46 17.8847 46 12.4849C46 7.13762 49.7707 2.96986 55.0839 2.96986C59.0504 2.96986 61.7438 5.06685 63.1149 8.13369L59.6626 10.0472C58.9525 8.08126 57.2386 6.90171 55.0839 6.90171C52.2926 6.90171 49.9176 9.10354 49.9176 12.4849C49.9176 15.9187 52.2926 18.0681 55.0839 18.0681C57.361 18.0681 59.3687 16.6265 60.0054 14.2412L63.4822 15.945Z"
        fill={color}
      />
      <path
        d="M97.2965 21.6592H101.111V7.97638H97.2965V21.6592Z"
        fill={color}
      />
      <path
        d="M99.2105 2C97.9395 2 96.909 3.04783 96.909 4.34038C96.909 5.63294 97.9395 6.68077 99.2105 6.68077C100.482 6.68077 101.512 5.63294 101.512 4.34038C101.512 3.04783 100.482 2 99.2105 2Z"
        fill={color}
      />
      <path
        d="M48 35.3091V26.1561H53.7891V27.2362H49.1356V30.0708H53.1628V31.1509H49.1356V35.3091H48Z"
        fill={color}
      />
      <path
        d="M54.6933 31.9938C54.6933 30.7659 55.0133 29.8564 55.6533 29.2653C56.1879 28.7742 56.8396 28.5286 57.6083 28.5286C58.4629 28.5286 59.1615 28.8283 59.7039 29.4277C60.2463 30.0229 60.5175 30.847 60.5175 31.9001C60.5175 32.7534 60.3965 33.4256 60.1546 33.9168C59.9166 34.4037 59.5673 34.7825 59.1068 35.0531C58.6503 35.3236 58.1508 35.4589 57.6083 35.4589C56.7381 35.4589 56.0337 35.1613 55.4952 34.5661C54.9606 33.9709 54.6933 33.1134 54.6933 31.9938ZM55.7762 31.9938C55.7762 32.8429 55.9498 33.4797 56.2971 33.9043C56.6445 34.3247 57.0815 34.5349 57.6083 34.5349C58.1312 34.5349 58.5664 34.3226 58.9137 33.898C59.261 33.4735 59.4346 32.8262 59.4346 31.9563C59.4346 31.1363 59.259 30.5161 58.9078 30.0957C58.5605 29.6712 58.1273 29.4589 57.6083 29.4589C57.0815 29.4589 56.6445 29.6691 56.2971 30.0895C55.9498 30.5099 55.7762 31.1446 55.7762 31.9938Z"
        fill={color}
      />
      <path
        d="M61.6968 35.3091V28.6785H62.6451V29.6837C62.887 29.2133 63.1095 28.9032 63.3124 28.7534C63.5192 28.6035 63.7455 28.5286 63.9914 28.5286C64.3465 28.5286 64.7075 28.6493 65.0743 28.8907L64.7114 29.9334C64.4538 29.7711 64.1963 29.6899 63.9387 29.6899C63.7085 29.6899 63.5016 29.7648 63.3182 29.9147C63.1348 30.0604 63.0041 30.2643 62.926 30.5265C62.809 30.9261 62.7504 31.3632 62.7504 31.8377V35.3091H61.6968Z"
        fill={color}
      />
      <path
        d="M65.6507 35.3091V28.6785H66.5931V29.6087C66.7882 29.2841 67.0477 29.0239 67.3716 28.8283C67.6955 28.6285 68.0642 28.5286 68.4779 28.5286C68.9384 28.5286 69.3149 28.6306 69.6076 28.8345C69.9042 29.0385 70.113 29.3236 70.2339 29.6899C70.7256 28.9157 71.3656 28.5286 72.1539 28.5286C72.7705 28.5286 73.2446 28.7118 73.5763 29.078C73.908 29.4402 74.0738 30 74.0738 30.7575V35.3091H73.0261V31.1322C73.0261 30.6826 72.9909 30.36 72.9207 30.1644C72.8544 29.9646 72.7314 29.8044 72.5519 29.6837C72.3724 29.563 72.1617 29.5026 71.9198 29.5026C71.4827 29.5026 71.1198 29.6587 70.831 29.9709C70.5422 30.2789 70.3978 30.7742 70.3978 31.4568V35.3091H69.3442V31.001C69.3442 30.5016 69.2584 30.127 69.0867 29.8772C68.915 29.6275 68.634 29.5026 68.2438 29.5026C67.9472 29.5026 67.6721 29.5858 67.4184 29.7523C67.1687 29.9188 66.9872 30.1623 66.874 30.4828C66.7609 30.8033 66.7043 31.2653 66.7043 31.8689V35.3091H65.6507Z"
        fill={color}
      />
      <path
        d="M79.8423 33.1738L80.931 33.3174C80.7593 33.9958 80.4413 34.5224 79.9769 34.897C79.5125 35.2716 78.9194 35.4589 78.1974 35.4589C77.2882 35.4589 76.5663 35.1613 76.0316 34.5661C75.5009 33.9667 75.2356 33.128 75.2356 32.0499C75.2356 30.9344 75.5048 30.0687 76.0434 29.4527C76.5819 28.8366 77.2804 28.5286 78.1389 28.5286C78.9701 28.5286 79.6491 28.8304 80.1759 29.4339C80.7027 30.0375 80.9661 30.8866 80.9661 31.9813C80.9661 32.0479 80.9642 32.1478 80.9603 32.281H76.3243C76.3633 33.0094 76.5565 33.5671 76.9038 33.9542C77.2511 34.3413 77.6843 34.5349 78.2033 34.5349C78.5896 34.5349 78.9194 34.4266 79.1925 34.2102C79.4657 33.9938 79.6823 33.6483 79.8423 33.1738ZM76.3829 31.3569H79.854C79.8072 30.7992 79.6745 30.3809 79.4559 30.102C79.1203 29.6691 78.6852 29.4527 78.1506 29.4527C77.6667 29.4527 77.2589 29.6254 76.9272 29.9709C76.5994 30.3163 76.418 30.7784 76.3829 31.3569Z"
        fill={color}
      />
      <path
        d="M82.1981 35.3091V28.6785H83.1464V29.6837C83.3883 29.2133 83.6108 28.9032 83.8137 28.7534C84.0205 28.6035 84.2468 28.5286 84.4927 28.5286C84.8478 28.5286 85.2088 28.6493 85.5756 28.8907L85.2127 29.9334C84.9551 29.7711 84.6976 29.6899 84.44 29.6899C84.2098 29.6899 84.0029 29.7648 83.8195 29.9147C83.6361 30.0604 83.5054 30.2643 83.4274 30.5265C83.3103 30.9261 83.2517 31.3632 83.2517 31.8377V35.3091H82.1981Z"
        fill={color}
      />
      <path
        d="M86.1286 35.3091V26.1561H87.1822V35.3091H86.1286Z"
        fill={color}
      />
      <path
        d="M88.7244 37.8626L88.6073 36.8075C88.8376 36.8741 89.0385 36.9074 89.2102 36.9074C89.4444 36.9074 89.6317 36.8658 89.7722 36.7825C89.9126 36.6993 90.0278 36.5827 90.1175 36.4329C90.1839 36.3205 90.2912 36.0416 90.4395 35.5963C90.459 35.5338 90.4902 35.4422 90.5331 35.3215L88.1742 28.6785H89.3097L90.6034 32.5182C90.7712 33.0052 90.9214 33.5172 91.0541 34.0541C91.175 33.538 91.3194 33.0343 91.4872 32.5432L92.816 28.6785H93.8696L91.5048 35.4214C91.2511 36.1498 91.0541 36.6514 90.9136 36.9261C90.7263 37.2966 90.5117 37.5671 90.2697 37.7378C90.0278 37.9126 89.739 38 89.4034 38C89.2005 38 88.9741 37.9542 88.7244 37.8626Z"
        fill={color}
      />
      <path
        d="M101.162 31.719V30.6452L104.797 30.6389V34.0354C104.239 34.5099 103.663 34.8678 103.07 35.1093C102.477 35.3465 101.868 35.4651 101.244 35.4651C100.401 35.4651 99.6341 35.2737 98.9434 34.8907C98.2565 34.5036 97.7375 33.9459 97.3863 33.2175C97.0351 32.4891 96.8595 31.6753 96.8595 30.7763C96.8595 29.8855 97.0332 29.0552 97.3805 28.2851C97.7317 27.5109 98.2351 26.9365 98.8907 26.5619C99.5463 26.1873 100.301 26 101.156 26C101.776 26 102.336 26.1082 102.836 26.3247C103.339 26.5369 103.733 26.8345 104.018 27.2175C104.303 27.6004 104.52 28.0999 104.668 28.7159L103.644 29.0156C103.515 28.5494 103.355 28.1831 103.164 27.9168C102.973 27.6504 102.699 27.4381 102.344 27.2799C101.989 27.1176 101.595 27.0364 101.162 27.0364C100.643 27.0364 100.194 27.1217 99.8155 27.2924C99.437 27.4589 99.1307 27.6795 98.8965 27.9542C98.6663 28.2289 98.4868 28.5307 98.358 28.8595C98.1395 29.4256 98.0302 30.0395 98.0302 30.7014C98.0302 31.5172 98.1609 32.1998 98.4224 32.7492C98.6877 33.2986 99.0721 33.7066 99.5755 33.9729C100.079 34.2393 100.614 34.3725 101.179 34.3725C101.671 34.3725 102.151 34.2726 102.619 34.0728C103.088 33.8689 103.443 33.6524 103.685 33.4235V31.719H101.162Z"
        fill={color}
      />
      <path
        d="M106.281 35.3091V28.6785H107.229V29.6837C107.471 29.2133 107.693 28.9032 107.896 28.7534C108.103 28.6035 108.329 28.5286 108.575 28.5286C108.93 28.5286 109.291 28.6493 109.658 28.8907L109.295 29.9334C109.038 29.7711 108.78 29.6899 108.522 29.6899C108.292 29.6899 108.085 29.7648 107.902 29.9147C107.719 30.0604 107.588 30.2643 107.51 30.5265C107.393 30.9261 107.334 31.3632 107.334 31.8377V35.3091H106.281Z"
        fill={color}
      />
      <path
        d="M114.291 34.4912C113.901 34.845 113.524 35.0947 113.161 35.2404C112.802 35.3861 112.416 35.4589 112.002 35.4589C111.319 35.4589 110.794 35.282 110.428 34.9282C110.061 34.5702 109.877 34.1145 109.877 33.5609C109.877 33.2362 109.946 32.9407 110.082 32.6743C110.223 32.4037 110.404 32.1873 110.627 32.025C110.853 31.8626 111.107 31.7399 111.388 31.6566C111.594 31.5983 111.907 31.5421 112.324 31.488C113.175 31.3798 113.801 31.2508 114.203 31.1009C114.207 30.9469 114.209 30.8491 114.209 30.8075C114.209 30.3496 114.109 30.0271 113.91 29.8398C113.641 29.5858 113.241 29.4589 112.71 29.4589C112.215 29.4589 111.848 29.5525 111.61 29.7399C111.376 29.923 111.202 30.2497 111.089 30.7201L110.059 30.5702C110.152 30.0999 110.307 29.7211 110.521 29.4339C110.736 29.1426 111.046 28.9199 111.452 28.7659C111.858 28.6077 112.328 28.5286 112.863 28.5286C113.393 28.5286 113.825 28.5952 114.156 28.7284C114.488 28.8616 114.732 29.0302 114.888 29.2341C115.044 29.4339 115.153 29.6878 115.216 29.9958C115.251 30.1873 115.268 30.5328 115.268 31.0323V32.5307C115.268 33.5754 115.29 34.2373 115.333 34.5161C115.38 34.7908 115.469 35.0552 115.602 35.3091H114.502C114.392 35.076 114.322 34.8033 114.291 34.4912ZM114.203 31.9813C113.821 32.1478 113.247 32.2893 112.482 32.4058C112.049 32.4724 111.743 32.5473 111.563 32.6306C111.384 32.7138 111.245 32.8366 111.148 32.999C111.05 33.1571 111.001 33.334 111.001 33.5297C111.001 33.8293 111.107 34.0791 111.317 34.2789C111.532 34.4787 111.844 34.5786 112.254 34.5786C112.66 34.5786 113.021 34.4849 113.337 34.2976C113.653 34.1061 113.885 33.846 114.033 33.5172C114.146 33.2633 114.203 32.8887 114.203 32.3933V31.9813Z"
        fill={color}
      />
      <path
        d="M120.89 35.3091V34.4724C120.496 35.1301 119.917 35.4589 119.152 35.4589C118.656 35.4589 118.2 35.3132 117.782 35.0219C117.369 34.7305 117.047 34.3247 116.816 33.8044C116.59 33.2799 116.477 32.6785 116.477 32C116.477 31.3382 116.58 30.7388 116.787 30.2019C116.994 29.6608 117.304 29.2466 117.718 28.9594C118.132 28.6722 118.594 28.5286 119.105 28.5286C119.48 28.5286 119.813 28.6139 120.106 28.7846C120.399 28.9511 120.637 29.1696 120.82 29.4402V26.1561H121.868V35.3091H120.89ZM117.56 32C117.56 32.8491 117.728 33.4839 118.063 33.9043C118.399 34.3247 118.795 34.5349 119.251 34.5349C119.712 34.5349 120.102 34.3351 120.422 33.9355C120.746 33.5317 120.908 32.9178 120.908 32.0937C120.908 31.1863 120.744 30.5203 120.416 30.0957C120.089 29.6712 119.685 29.4589 119.205 29.4589C118.736 29.4589 118.344 29.6629 118.028 30.0708C117.716 30.4787 117.56 31.1217 117.56 32Z"
        fill={color}
      />
      <path
        d="M123.486 27.4485V26.1561H124.54V27.4485H123.486ZM123.486 35.3091V28.6785H124.54V35.3091H123.486Z"
        fill={color}
      />
      <path
        d="M130.355 33.1738L131.444 33.3174C131.272 33.9958 130.954 34.5224 130.49 34.897C130.025 35.2716 129.432 35.4589 128.71 35.4589C127.801 35.4589 127.079 35.1613 126.545 34.5661C126.014 33.9667 125.748 33.128 125.748 32.0499C125.748 30.9344 126.018 30.0687 126.556 29.4527C127.095 28.8366 127.793 28.5286 128.652 28.5286C129.483 28.5286 130.162 28.8304 130.689 29.4339C131.216 30.0375 131.479 30.8866 131.479 31.9813C131.479 32.0479 131.477 32.1478 131.473 32.281H126.837C126.876 33.0094 127.069 33.5671 127.417 33.9542C127.764 34.3413 128.197 34.5349 128.716 34.5349C129.103 34.5349 129.432 34.4266 129.705 34.2102C129.979 33.9938 130.195 33.6483 130.355 33.1738ZM126.896 31.3569H130.367C130.32 30.7992 130.187 30.3809 129.969 30.102C129.633 29.6691 129.198 29.4527 128.664 29.4527C128.18 29.4527 127.772 29.6254 127.44 29.9709C127.112 30.3163 126.931 30.7784 126.896 31.3569Z"
        fill={color}
      />
      <path
        d="M132.723 35.3091V28.6785H133.671V29.6212C134.128 28.8928 134.787 28.5286 135.649 28.5286C136.024 28.5286 136.368 28.6015 136.68 28.7471C136.996 28.8887 137.232 29.076 137.388 29.3091C137.544 29.5421 137.653 29.8189 137.716 30.1394C137.755 30.3476 137.774 30.7118 137.774 31.232V35.3091H136.721V31.2758C136.721 30.8179 136.68 30.4766 136.598 30.2518C136.516 30.0229 136.369 29.8418 136.159 29.7086C135.952 29.5713 135.708 29.5026 135.427 29.5026C134.978 29.5026 134.59 29.6545 134.262 29.9584C133.938 30.2622 133.776 30.8387 133.776 31.6878V35.3091H132.723Z"
        fill={color}
      />
      <path
        d="M141.646 34.3038L141.798 35.2966C141.502 35.3632 141.236 35.3965 141.002 35.3965C140.62 35.3965 140.323 35.3319 140.113 35.2029C139.902 35.0739 139.754 34.9053 139.668 34.6972C139.582 34.4849 139.539 34.0416 139.539 33.3673V29.5525H138.766V28.6785H139.539V27.0364L140.587 26.3621V28.6785H141.646V29.5525H140.587V33.4298C140.587 33.7503 140.604 33.9563 140.639 34.0479C140.678 34.1394 140.739 34.2123 140.821 34.2664C140.907 34.3205 141.028 34.3476 141.184 34.3476C141.301 34.3476 141.455 34.333 141.646 34.3038Z"
        fill={color}
      />
      <path
        d="M146.689 34.4912C146.299 34.845 145.922 35.0947 145.559 35.2404C145.2 35.3861 144.814 35.4589 144.4 35.4589C143.717 35.4589 143.192 35.282 142.826 34.9282C142.459 34.5702 142.275 34.1145 142.275 33.5609C142.275 33.2362 142.344 32.9407 142.48 32.6743C142.621 32.4037 142.802 32.1873 143.025 32.025C143.251 31.8626 143.505 31.7399 143.785 31.6566C143.992 31.5983 144.304 31.5421 144.722 31.488C145.573 31.3798 146.199 31.2508 146.601 31.1009C146.605 30.9469 146.607 30.8491 146.607 30.8075C146.607 30.3496 146.507 30.0271 146.308 29.8398C146.039 29.5858 145.639 29.4589 145.108 29.4589C144.613 29.4589 144.246 29.5525 144.008 29.7399C143.774 29.923 143.6 30.2497 143.487 30.7201L142.457 30.5702C142.55 30.0999 142.705 29.7211 142.919 29.4339C143.134 29.1426 143.444 28.9199 143.85 28.7659C144.256 28.6077 144.726 28.5286 145.261 28.5286C145.791 28.5286 146.222 28.5952 146.554 28.7284C146.886 28.8616 147.13 29.0302 147.286 29.2341C147.442 29.4339 147.551 29.6878 147.614 29.9958C147.649 30.1873 147.666 30.5328 147.666 31.0323V32.5307C147.666 33.5754 147.688 34.2373 147.731 34.5161C147.778 34.7908 147.867 35.0552 148 35.3091H146.9C146.79 35.076 146.72 34.8033 146.689 34.4912ZM146.601 31.9813C146.219 32.1478 145.645 32.2893 144.88 32.4058C144.447 32.4724 144.141 32.5473 143.961 32.6306C143.782 32.7138 143.643 32.8366 143.545 32.999C143.448 33.1571 143.399 33.334 143.399 33.5297C143.399 33.8293 143.505 34.0791 143.715 34.2789C143.93 34.4787 144.242 34.5786 144.652 34.5786C145.058 34.5786 145.419 34.4849 145.735 34.2976C146.051 34.1061 146.283 33.846 146.431 33.5172C146.544 33.2633 146.601 32.8887 146.601 32.3933V31.9813Z"
        fill={color}
      />
    </svg>
  )
}

Logo.defaultProps = {
  color: "#fff",
}

export { Logo }
