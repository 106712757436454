const texts = [
  "Check out %GRADIENT_NAME% by Codioful, a beautiful multilayered and multicolored CSS Gradient for your next project by @HiShahadat #cssgradient #codioful %GRADIENT_LINK%",
  "I just found %GRADIENT_NAME% is an useful #CSS #gradient by @HiShahadat Also you can download in JPG version, sweet! %GRADIENT_LINK%",
  "Old 2-3 color stop gradients has gone. Check out %GRADIENT_NAME%, a multi-layered #cssgradient with blend modes technique by @HiShahadat %GRADIENT_LINK%",
  "This is new, multiple gradients stacked with blend modes to create this #gradient, 100%25 #CSS, don't need to use jpeg from now on! #cssgradient %GRADIENT_LINK%",
  "I just found a gradient background for my project, 100%25 CSS with downloadable JPG %26 PNG. %GRADIENT_LINK%",
  "I think this is fun to see a multiple layered CSS gradient. Check out %GRADIENT_NAME% by Codioful. %GRADIENT_LINK%",
  "CSS gradient with blend mode techniques? Ok, this gradient shows new opportunities to make gradients better than ever! %GRADIENT_LINK%",
]

export const tweetText = (title, url) => {
  const selectText = texts[Math.round(Math.random() * (texts.length - 1))]
  const text = selectText
    .replace(/#/g, "%23")
    .replace(/%GRADIENT_NAME%/, title)
    .replace(/%GRADIENT_LINK%/, url)
  return text
}
