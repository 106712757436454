import React from "react"

import "./input.css"

const Input = ({ btn, name, type, input, placeholder, onChange }) => {
  return (
    <input
      className={`input ${btn}`}
      name={name}
      value={input}
      onChange={onChange}
      type={type}
      placeholder={placeholder}
    />
  )
}

export { Input }
