import React, { useState } from "react"
import { Link } from "gatsby"

import { Brand } from "./brand"

import "./nav.css"

const Nav = ({ isLight }) => {
  const [show, setShow] = useState(false)
  const color = isLight ? "#0D004D" : "#fff"
  return (
    <div
      className={`${show ? "show" : ""} nav-main ${
        isLight ? "light" : "normal"
      }`}
    >
      <Brand color={color} />
      <div className="menu-bar">
        <ul onClick={() => setShow(false)}>
          <li className="editor">
            <Link to="/editor">
              <span>ALPHA</span>Codioful Editor
            </Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/license">License</Link>
          </li>
          <li>
            <Link to="/privacy">Privacy</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
        <div
          role="button"
          tabIndex={0}
          onKeyPress={() => false}
          onClick={() => setShow(!show)}
          className="mobile-nav"
        >
          <div className="nav-icon">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </div>
  )
}

Nav.defaultProps = {
  mode: "dark",
}

export { Nav }
