import React from "react"

import { Button, MinButton } from "../common"

const DownloadButton = ({ handleDownload, fileFormat, downloading }) => {
  return (
    <div className="download-button">
      <Button onClick={handleDownload} btn="button-dark-bg button-download">
        <MinButton
          title={`${downloading ? `Downloading...` : `Download ${fileFormat}`}`}
          leftIcon={downloading ? "animation" : "icon-download"}
        />
      </Button>
    </div>
  )
}

export default DownloadButton
