import React from "react"

import "./sorter.css"

const Sorter = ({
  tags,
  activeTag,
  types,
  activeType,
  setActiveType,
  onClick,
  color,
  setColor,
}) => {
  return (
    <div className="sort">
      <ul>
        {types.map(type => (
          <li
            onClick={() => onClick(type)}
            className={activeType === type ? "active" : ""}
            key={type}
          >
            {type}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Sorter
