import React from "react"
import { CustomPicker } from "react-color"
import {
  Saturation,
  Alpha,
  Hue,
  EditableInput,
} from "react-color/lib/components/common"

import "./colorpicker.css"

const HueDot = () => {
  return (
    <div
      style={{
        position: "relative",
        left: -7,
        top: -10,
        backgroundColor: "#fff",
        width: 20,
        height: 20,
        borderRadius: 100,
        boxShadow:
          "0px 2px 4px rgba(13, 0, 77, 0.12), 0px 2px 16px rgba(13, 0, 77, 0.12)",
      }}
    />
  )
}

const AlphaDot = () => {
  return (
    <div
      style={{
        position: "relative",
        top: -7,
        left: -10,
        backgroundColor: "#fff",
        width: 20,
        height: 20,
        borderRadius: 100,
        boxShadow:
          "0px 2px 4px rgba(13, 0, 77, 0.12), 0px 2px 16px rgba(13, 0, 77, 0.12)",
      }}
    />
  )
}

const ColorPickerComponent = props => {
  const SaturationDot = () => {
    return (
      <div
        className="saturation_dot"
        style={{
          position: "relative",
          backgroundColor: props.hex,
          top: -10,
          left: -10,
          width: 20,
          height: 20,
          borderWidth: 2,
          borderStyle: "solid",
          borderColor: "#fff",
          borderRadius: 100,
          cursor: "pointer",
          boxShadow: "0 0 0 2px #000",
        }}
      />
    )
  }
  console.log({ props })
  return (
    <div className="color_picker_container">
      <div>
        <div style={{ display: "flex" }}>
          <div
            style={{
              position: "relative",
              width: 200,
              height: 200,
              borderRadius: 3,
            }}
          >
            <Saturation {...props} pointer={SaturationDot} />
          </div>
          <div
            style={{
              position: "relative",
              width: 6,
              height: 200,
              marginLeft: 23,
              borderRadius: 3,
            }}
          >
            <Hue {...props} direction="vertical" pointer={HueDot} />
          </div>
        </div>
        <div
          style={{
            position: "relative",
            width: 200,
            height: 6,
            marginTop: 23,
            borderRadius: 3,
          }}
        >
          <Alpha {...props} pointer={AlphaDot} />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 23,
          }}
        >
          <div>
            <p style={{ fontSize: 14, color: "#716CA6" }}>Hex</p>
            <EditableInput
              {...props}
              style={{
                input: {
                  width: "100%",
                  borderWidth: 0,
                  borderBottom: 2,
                  borderStyle: "solid",
                  borderColor: "#DDDBF1",
                },
                wrap: {
                  width: 80,
                },
              }}
              value={props.hex}
            />
          </div>
          <div>
            <p style={{ fontSize: 14, color: "#716CA6" }}>Alpha</p>
            <EditableInput
              {...props}
              style={{
                input: {
                  width: "100%",
                  borderWidth: 0,
                  borderBottom: 2,
                  borderStyle: "solid",
                  borderColor: "#DDDBF1",
                },
                wrap: {
                  width: 48,
                },
              }}
              onChange={props.onAlphaChange}
              value={props.rgb.a * 100 || 0}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export const ColorPicker = CustomPicker(ColorPickerComponent)
