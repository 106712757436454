import React, { useEffect, useRef, useState } from "react"

import "./editorpickerground.css"

const EditorPickerGround = ({ children, type, background, handlePosition }) => {
  const groundRef = useRef(null)
  const [shouldMove, setShouldMove] = useState(false)
  useEffect(() => {
    window.addEventListener("mouseup", unsetShouldMove)
    return () => {
      window.removeEventListener("mouseup", unsetShouldMove)
    }
  })
  const unsetShouldMove = () => {
    setShouldMove(false)
  }
  const handleMouseMove = e => {
    e.stopPropagation()
    if (!type.includes("linear-gradient")) {
      const ground = groundRef?.current
      if (ground && shouldMove) {
        const scrolledY = window.scrollY
        const x = e.clientX - ground.offsetLeft
        const y = e.clientY + scrolledY - ground.offsetTop
        const width = ground.offsetWidth
        const height = ground.offsetHeight
        const xPercentage = Math.round((x / width) * 100)
        const yPercentage = Math.round((y / height) * 100)
        handlePosition(e, [xPercentage, yPercentage])
      }
    }
  }
  return (
    <div
      className="gradient-picker-ground"
      role="button"
      tabIndex={0}
      onKeyPress={() => false}
      ref={groundRef}
      onMouseMove={handleMouseMove}
      onMouseDown={() => setShouldMove(true)}
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 296,
        height: 296,
        background,
        borderRadius: 3,
      }}
    >
      <div className="gradient-picker-ground-tools">{children}</div>
    </div>
  )
}

export { EditorPickerGround }
