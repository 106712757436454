import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import ItemHeader from "../item/itemheader"

import { Header } from "./header"
import { Footer } from "./footer"

import "./layout.css"

const Layout = ({
  children,
  item,
  title,
  defaultGradient,
  footerGradient,
  description,
  noExtraFootPad,
  stats,
  totalItems,
  noIntro,
}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      {item ? (
        <ItemHeader item={item} description={description} />
      ) : (
        <Header
          title={title}
          description={description}
          defaultGradient={defaultGradient}
          siteTitle={data.site.siteMetadata.title}
          noIntro={noIntro}
        />
      )}
      <div>
        <main>{children}</main>
      </div>
      {!noIntro && (
        <Footer
          footerGradient={footerGradient}
          noExtraFootPad={noExtraFootPad}
          stats={stats}
          totalItems={totalItems}
        />
      )}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export { Layout }
