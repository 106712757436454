import React from "react"

import "./loadingcircle.css"

const LoadingCircle = ({ type }) => {
  return <div className="lds-dual-ring"></div>
}

LoadingCircle.defaultProps = {
  type: "normal",
}

export { LoadingCircle }
