import React from "react"

import "./inputstep.css"

const InputStep = props => {
  const { value, min, max, onChange, onMouseUp } = props
  const onKeyDown = e => {
    e.preventDefault()
    const { target, key, shiftKey, ctrlKey } = e
    const { value: rawValue, min: rawMin, max: rawMax } = target
    const [value, min, max] = [Number(rawValue), Number(rawMin), Number(rawMax)]
    if (key === "ArrowUp") {
      if (shiftKey) {
        if (value + 10 <= max) onChange(Number(value) + 10)
      } else if (ctrlKey) {
        if (value + 1 <= max) onChange(Number(value) + 1)
      } else {
        if (value + 0.01 <= max) onChange(Number(value) + 0.01)
      }
    } else if (key === "ArrowDown") {
      if (shiftKey) {
        if (value - 10 >= min) onChange(Number(value) - 10)
      } else if (ctrlKey) {
        if (value - 1 >= min) onChange(Number(value) - 1)
      } else {
        if (value - 0.01 >= min) onChange(Number(value) - 0.01)
      }
    }
  }
  return (
    <input
      {...props}
      type="number"
      onKeyDown={onKeyDown}
      onChange={onChange}
      onMouseUp={onMouseUp && onMouseUp}
      value={value}
      min={min}
      max={max}
    />
  )
}

InputStep.defaultProps = {
  min: 0,
  max: 100,
}

export { InputStep }
