import React, { useState, useRef } from "react"
import styled from "styled-components"
import ReactGA from "react-ga"
import * as htmlToImage from "html-to-image"
import download from "downloadjs"

import DownloadButton from "./downloadbutton"

import "./downloadbox.css"

const MAX_IMAGE_PX = 300
const ESC_KEYCODE = 27

const DownloadView = styled.div`
  ${props => props.CSS};
  height: ${props => props.size.imgHeight + "px"};
  width: ${props => props.size.imgWidth + "px"};
`

const SIZES = {
  "1:1": {
    widthX: 1,
    heightX: 1,
    width: 1000,
    height: 1000,
    imgWidth: 300,
    imgHeight: 300,
  },
  "3:2": {
    widthX: 3,
    heightX: 2,
    width: 3000,
    height: 2000,
    imgWidth: 300,
    imgHeight: 200,
  },
  "4:3": {
    widthX: 4,
    heightX: 3,
    width: 4000,
    height: 3000,
    imgWidth: 300,
    imgHeight: 225,
  },
  "16:9": {
    widthX: 16,
    heightX: 9,
    width: 4000,
    height: 2250,
    imgWidth: 300,
    imgHeight: 169,
  },
}

const FILE_FORMATS = ["JPG", "PNG", "SVG"]

const DownloadBox = ({ id, CSS, handleShow }) => {
  const gradientRef = useRef(null)
  const sizesArray = Object.keys(SIZES)
  const [linked, setLinked] = useState(true)
  const [ratio, setRatio] = useState(sizesArray[1])
  const [size, setSize] = useState(SIZES[ratio])
  const [fileFormat, setFileFormat] = useState(FILE_FORMATS[0])
  const [downloading, setDownloading] = useState(false)

  const handleInput = e => {
    const value = Number(e.target.value)
    const name = e.target.name
    if (!isNaN(value)) {
      if (linked) {
        const divider = size.widthX / size.heightX
        let imgWidth = value
        let imgHeight = value
        if (value > MAX_IMAGE_PX) {
          imgHeight = MAX_IMAGE_PX
          imgWidth = MAX_IMAGE_PX
        }
        setSize({
          ...size,
          width: name === "width" ? value : value * divider,
          height: name === "height" ? value : value / divider,
          imgWidth: imgWidth,
          imgHeight: imgHeight / divider,
        })
      } else {
        const currentValue = Number(value)
        const anotherValue = name === "width" ? size.height : size.width
        if (currentValue > MAX_IMAGE_PX || anotherValue > MAX_IMAGE_PX) {
          let imgHeight, imgWidth, divider
          if (name === "width") {
            if (currentValue > size.height) {
              divider = currentValue / MAX_IMAGE_PX
              imgWidth = MAX_IMAGE_PX
              imgHeight = size.height / divider
            } else {
              divider = size.height / MAX_IMAGE_PX
              imgHeight = MAX_IMAGE_PX
              imgWidth = currentValue / divider
            }
          } else {
            if (currentValue > size.width) {
              divider = currentValue / MAX_IMAGE_PX
              imgHeight = MAX_IMAGE_PX
              imgWidth = size.width / divider
            } else {
              divider = size.width / MAX_IMAGE_PX
              imgWidth = MAX_IMAGE_PX
              imgHeight = currentValue / divider
            }
          }
          setSize({ ...size, [name]: value, imgWidth, imgHeight })
        } else {
          let imgHeight, imgWidth
          if (name === "width") {
            imgWidth = currentValue
            imgHeight = size.height
          } else {
            imgHeight = currentValue
            imgWidth = size.width
          }
          setSize({ ...size, [name]: value, imgWidth, imgHeight })
        }
      }
    }
  }

  const handleRatio = index => {
    setRatio(sizesArray[index])
    setSize(SIZES[sizesArray[index]], size)
  }

  const handleDownload = () => {
    const options = {
      width: size.width,
      height: size.height,
    }
    if (gradientRef.current) {
      const gradient = gradientRef.current
      setDownloading(true)
      if (fileFormat === "JPG") {
        htmlToImage
          .toJpeg(gradient, options)
          .then(function(dataUrl) {
            download(dataUrl, `${id}@${options.width}x${options.height}px.jpg`)
            ReactGA.pageview(`/$gr-jpg-download/${id}`)
            setDownloading(false)
          })
          .catch(function(error) {
            console.error("oops, something went wrong!", error)
            setDownloading(false)
          })
      } else if (fileFormat === "PNG") {
        htmlToImage
          .toPng(gradient, options)
          .then(function(dataUrl) {
            download(dataUrl, `${id}@${options.width}x${options.height}px.png`)
            ReactGA.pageview(`/$gr-png-download/${id}`)
            setDownloading(false)
          })
          .catch(function(error) {
            console.error("oops, something went wrong!", error)
            setDownloading(false)
          })
      } else if (fileFormat === "SVG") {
        htmlToImage
          .toSvgDataURL(gradient, options)
          .then(function(dataUrl) {
            download(dataUrl, `${id}@${options.width}x${options.height}px.svg`)
            ReactGA.pageview(`/$gr-svg-download/${id}`)
            setDownloading(false)
          })
          .catch(function(error) {
            console.error("oops, something went wrong!", error)
            setDownloading(false)
          })
      }
    }
  }

  const handleOutsideClick = e => {
    if (e.target.className === "download-option") {
      handleShow()
    }
  }

  const handleKeyboardInput = e => {
    if (e.keyCode === ESC_KEYCODE) {
      handleShow()
    }
  }

  return (
    <div
      role="button"
      tabIndex={0}
      onKeyDown={handleKeyboardInput}
      onClick={handleOutsideClick}
      className="download-option"
    >
      <div className="download-box">
        <div className="download-view-container">
          <DownloadView
            ref={gradientRef}
            className="download-view"
            size={size}
            CSS={CSS}
          />
        </div>
        <div className="download-format">
          <ul className="download-sizes">
            {sizesArray.map((size, index) => (
              <li
                key={index}
                onClick={() =>
                  linked
                    ? handleRatio(index)
                    : setLinked(true) & handleRatio(index)
                }
                className={`${
                  linked && ratio === size ? "active" : "not-active"
                }`}
              >
                {size}
              </li>
            ))}
          </ul>
          <ul className="download-file">
            {FILE_FORMATS.map((file, index) => (
              <li
                onClick={() => setFileFormat(FILE_FORMATS[index])}
                key={index}
                className={fileFormat === file ? "active" : ""}
              >
                {file}
              </li>
            ))}
          </ul>
        </div>
        <div className="download-size">
          <div className="size-input">
            <input
              onChange={handleInput}
              name="width"
              type="text"
              value={size.width}
              placeholder="width"
            />
          </div>
          <div
            role="button"
            tabIndex={0}
            onKeyPress={() => false}
            className={`link-icon ${linked ? "linked" : "unlinked"}`}
            onClick={() => setLinked(!linked)}
          >
            <span className={`icon icon-${linked ? "link" : "unlink"}`} />
          </div>
          <div className="size-input">
            <input
              onChange={handleInput}
              name="height"
              type="text"
              value={size.height}
              placeholder="height"
            />
          </div>
        </div>
        <DownloadButton
          handleDownload={handleDownload}
          fileFormat={fileFormat}
          downloading={downloading}
        />
        <div className="download-text">
          <p>Dimensions are set on pixel (px)</p>
          <p className={fileFormat === "SVG" ? "active" : ""}>
            SVG works in browser only
          </p>
        </div>
        <div className="download-action">
          <div
            role="button"
            tabIndex={0}
            onKeyPress={() => false}
            onClick={handleShow}
            className="download-close"
          >
            <span className="icon icon-close" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default DownloadBox
