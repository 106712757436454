import React, { useEffect, useState } from "react"

import "./inputslider.css"

const InputSlider = props => {
  const { value, min, max, onChange, onMouseUp } = props
  const [step, setStep] = useState(0.01)
  useEffect(() => {
    window.addEventListener("keydown", onKeyDown)
    window.addEventListener("keyup", onKeyUp)
    return () => {
      window.removeEventListener("keydown", onKeyDown)
      window.removeEventListener("keyup", onKeyUp)
    }
  })
  const onKeyDown = e => {
    const { key } = e
    if (key === "Control") {
      setStep(1)
    } else if (key === "Shift") {
      setStep(10)
    } else {
      setStep(0.01)
    }
  }
  const onKeyUp = () => {
    setStep(0.01)
  }
  return (
    <input
      {...props}
      type="range"
      step={step}
      min={min}
      max={max}
      onChange={onChange}
      onMouseUp={onMouseUp && onMouseUp}
      value={value}
      className="slider"
    />
  )
}

InputSlider.defaultProps = {
  min: 0,
  max: 100,
}

export { InputSlider }
