import React, { useState } from "react"
import axios from "axios"

import { Input } from "./input"

import "./subscription.css"
import { LoadingDot } from "./loadingdot"

const API_URL = "https://server.gradienta.io"
const EMAIL_VALIDITY_REGEX = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/

const Subscription = () => {
  const [status, setStatus] = useState({
    done: false,
    loading: false,
  })
  const [email, setEmail] = useState({
    id: "",
    valid: false,
  })
  const handleEmail = e => {
    setEmail({
      ...email,
      id: e.target.value,
      valid: EMAIL_VALIDITY_REGEX.test(e.target.value),
    })
  }
  const handleSubmit = async e => {
    e.preventDefault()
    if (email.valid) {
      setStatus({ ...status, loading: true })
      const { data } = await axios.post(`${API_URL}/confirmEmail`, {
        email: email.id,
      })
      setStatus({ ...status, done: data.email === "success", loading: false })
    }
  }

  return (
    <div className="subscription-main">
      <h4>
        Would You Like To Know <br />
        About Our Next Product &amp; Free Tools Like This?
      </h4>
      <form onSubmit={handleSubmit}>
        <div className="subscription-form">
          <Input
            name="EMAIL"
            type="email"
            input={email.id}
            placeholder="Enter your active email address"
            onChange={handleEmail}
          />
          <button
            className={
              status.done ? "done" : email.valid ? "working" : "disabled"
            }
            type="submit"
          >
            {status.done ? (
              `You're in!`
            ) : status.loading ? (
              <LoadingDot />
            ) : (
              "Subscribe"
            )}
          </button>
        </div>
        <p>
          <small>We don’t spam, only offers, updates &amp; new products!</small>
        </p>
      </form>
      {status.done && (
        <div className="msg-alert success">
          <div className="msg-status">
            <img alt="okay" src={require("../../assets/icon/okay.svg")} />
          </div>
          <p>
            Thanks for your subscription, please check your email inbox
            (promotion tab or spam folder), and click on the confirmation link.
          </p>
        </div>
      )}
    </div>
  )
}

export { Subscription }
