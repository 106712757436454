import React from "react"
import styled from "styled-components"

import "./button.css"

const ButtonMain = styled.button`
  ${props => props.btnStyle}
`

const Button = ({ children, btn, onClick, btnStyle }) => {
  return (
    <ButtonMain
      className={`button-main ${btn ? btn : ""}`}
      onClick={onClick}
      btnStyle={btnStyle}
    >
      {children}
    </ButtonMain>
  )
}

export { Button }
