import React from "react"
import { Link } from "gatsby"

import "./brand.css"

import { Logo } from "./logo"

const Brand = ({ color }) => {
  return (
    <div className="brand">
      <Link to="/">
        <Logo color={color} />
      </Link>
    </div>
  )
}

Brand.defaultProps = {
  color: "#fff",
}

export { Brand }
