import React, { useState, useEffect } from "react"
import styled from "styled-components"
import ReactGA from "react-ga"

import DownloadBox from "../item/downloadbox"

import { Button, MinButton } from "../common"

import "./liveview.css"

const CircleColor = styled.div`
  ${props => props.bgColor};
`

const LiveView = ({ isLight, title, id, css: CSS }) => {
  const DEFAULT_COPY_TEXT = "Copy CSS"
  const [copyText, setCopyText] = useState(DEFAULT_COPY_TEXT)
  const [show, setShow] = useState(false)

  useEffect(() => {
    let copyTextTimeout
    if (copyText !== DEFAULT_COPY_TEXT) {
      copyTextTimeout = setTimeout(() => {
        setCopyText(DEFAULT_COPY_TEXT)
      }, 1000)
    }
    return () => {
      clearTimeout(copyTextTimeout)
    }
  })

  const handleCSSCopy = () => {
    if (typeof navigator !== "undefined") {
      navigator.clipboard.writeText(CSS).then(() => {
        setCopyText("CSS Copied")
        ReactGA.pageview(`/$gr-css-copy/${id}`)
      })
    }
  }

  const handleShow = () => {
    setShow(!show)
  }

  return (
    <div className="mobile-screen">
      <CircleColor className="circle-color" bgColor={CSS} />
      <h4>
        Hello <strong>{title}</strong>
      </h4>
      <p>You Look Very Beautiful On A Smartphone</p>
      <div className="action">
        <ul>
          <li>
            <Button onClick={() => setShow(true)} btnStyle={CSS}>
              <MinButton
                btnStyle={{
                  color: isLight ? "#0D004D" : "#fff",
                  textShadow:
                    "0px 1px 2px rgba(13, 0, 77, 0.08), 0px 2px 4px rgba(13, 0, 77, 0.08), 0px 4px 8px rgba(13, 0, 77, 0.08), 0px 6px 12px rgba(13, 0, 77, 0.06)",
                }}
                leftIcon="icon-download"
                title="Download JPG"
              />
            </Button>
          </li>
          <li>
            <Button onClick={handleCSSCopy} btnStyle={{ background: `none` }}>
              <MinButton
                btnStyle={{ color: "#0D004D" }}
                leftIcon="icon-copy"
                title={copyText}
              />
            </Button>
          </li>
        </ul>
      </div>
      {show && <DownloadBox id={id} CSS={CSS} handleShow={handleShow} />}
    </div>
  )
}

export default LiveView
